import { useEffect, useState, useContext } from "react";
import { BulkActionsProps } from "./../BulkActions";
import { Clear24Icon, Edit24Icon, ExternalLink24Icon, Lock24Icon } from "@kaltura/ds-react-icons";
import { addBaseUrl, translate, useMediaQuery } from "@mediaspace/shared/utils";
import { StyledRowActions, TooltipPopperProps } from "./../../fileRow/row-actions/StyleComponents";
import { UploadStatus } from "@mediaspace/shared/upload";
import ChangingButton from "./../../fileRow/row-actions/ChangingButton";
import { useTheme } from "@mui/material";
import { Tooltip } from "@kaltura/ds-react-components";
import { ConfigContext, defaultContext } from "@mediaspace/shared/data-kms-config";

function BulkActionsButtons ({
                        files,
                        onCancel,
                        onPublish,
                        onEdit
                    }: BulkActionsProps) {

    const theme = useTheme();
    const context = useContext(ConfigContext) || defaultContext;
    const isXsScreen = useMediaQuery(
        theme.breakpoints.down(theme.breakpoints.values.sm)
    );

    const [showCancelAllButton, setShowCancelAllButton] = useState(false);
    const [showEditAllButton, setShowEditAllButton] = useState(false);

    useEffect(() => {
        const index = files.findIndex((file) =>
            file.fileMetaData.status === UploadStatus.IN_QUEUE ||
            file.fileMetaData.status === UploadStatus.UPLOADING
        );
        setShowCancelAllButton(index !== -1);

        const i = files.findIndex((file) =>
            file.fileMetaData.status === UploadStatus.READY ||
            file.fileMetaData.status === UploadStatus.PROCESSING ||
            file.fileMetaData.status === UploadStatus.UPLOADING
        );
        setShowEditAllButton(i !== -1);
    }, [files]);

    const myMediaTarget = context.application.isKAF ? "" : {target: "_blank"};

    return (
        <StyledRowActions className={"kms-ds-upload-media-bulk-actions-row-actions"}>
            {showCancelAllButton ?
                <ChangingButton icon={<Clear24Icon/>} onClick={() => onCancel()} label={translate("Cancel All Uploads")} />
                : <ChangingButton icon={<ExternalLink24Icon/>} href={addBaseUrl('/my-media')} {...myMediaTarget} label={translate("My Media")} />
            }
            {!isXsScreen &&
                <Tooltip title={translate("Coming Soon")} placement={"top"} PopperProps={TooltipPopperProps} describeChild={true}>
                    <span>
                        <ChangingButton icon={<Lock24Icon/>} onClick={() => onPublish()} label={translate("Set Privacy To All")} disabled={true} />
                    </span>
                </Tooltip>
            }
            <ChangingButton icon={<Edit24Icon/>} onClick={() => onEdit()} label={translate("Edit All")} disabled={!showEditAllButton}/>
        </StyledRowActions>
    );
}

export default BulkActionsButtons;
