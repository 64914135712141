import styled from "@emotion/styled";

/**
 * screen-read only base styling.
 * used for adding invisible content to the page for accessibility purposes.
 */
const SrOnly = (component: keyof JSX.IntrinsicElements) => styled(component)`
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
`;

/**
 * screen-read only as a span element
 */
export const SpanSrOnly = SrOnly("span");

/**
 * screen-read only as a label element
 */
export const LabelSrOnly = SrOnly("label");
