import styled from "@emotion/styled";
import { Carousel } from "../carousel/Carousel";
import { CarouselItem } from "../carousel/CarouselItem";
import { useTitle } from "./../list-title/useTitle";
import { ListTitle, ListTitleProps } from "./../list-title/ListTitle";
import { EventPlatformEntry } from "@mediaspace/shared/types";
import { systemWidth } from "@mediaspace/shared/styled";
import { useTimeShiftedArray } from "@mediaspace/hooks";
import { translate} from "@mediaspace/shared/utils";

export interface SinglePlaylistProps extends ListTitleProps {
    entries: EventPlatformEntry[];
    playlistId?: string;
    categoryId?: number;
    noLink?: boolean;
    itemsPerPage?: 2 | 3 | 4 | 5;
    timeShift?: number; // time shift in minutes
    fullScreenWidth?: boolean;
}

const StyledTitleContainer = styled("div", {
    shouldForwardProp(propName) {
        return propName !== "fullScreenWidth";
    },
})<{ fullScreenWidth?: boolean }>(({ theme, fullScreenWidth }) => ({
    "&": !fullScreenWidth && systemWidth({ theme }),
}));

/**
 * SinglePlaylist component displays vod / live entries in a carousel
 */
export function SinglePlaylist(props: SinglePlaylistProps) {
    const {
        entries,
        playlistId = "",
        categoryId,
        noLink = false,
        itemsPerPage = 4,
        timeShift = 0,
        fullScreenWidth = false
    } = props;

    const titleProps = useTitle(props);

    const carouselEntries = useTimeShiftedArray(
        entries,
        // 1 minute = 60,000 milliseconds
        timeShift * 60000
    );

    if (!entries.length) {
        return null;
    }

    return (
        <div className="single-playlist kms-ds-homepage-component-single-playlist" >
            {titleProps.show && (
                <StyledTitleContainer fullScreenWidth={fullScreenWidth} className="kms-ds-single-playlist-title">
                    <ListTitle {...titleProps} analyticsActionText={"Playlist - See all"} linkAriaLabel={translate('See all media in %1 playlist',[titleProps.translatedTitle])}/>
                </StyledTitleContainer>
            )}
            <Carousel
                items={carouselEntries}
                itemComponent={CarouselItem}
                itemProps={{
                    playlistId,
                    categoryId,
                    noLink,
                }}
                currentCardsNumberInSlides={itemsPerPage}
                minPossibleCardsNumberInSlides={itemsPerPage}
                container={"Playlist"}
                fullScreenWidth={fullScreenWidth}
            />
        </div>
    );
}

export default SinglePlaylist;
