import {FileRowProps} from "./../FileRow";
import {Clear24Icon, Edit24Icon, ExternalLink24Icon, Lock24Icon, Link24Icon, Globe24Icon} from "@kaltura/ds-react-icons";
import {getEntryUrl, translate, useMediaQuery} from "@mediaspace/shared/utils";
import {FileMetaData, UploadStatus} from "@mediaspace/shared/upload";
import { PublishStatus } from "@mediaspace/shared/types";
import { StyledRowActions, TooltipPopperProps } from "./StyleComponents";
import ChangingButton from "./ChangingButton";
import {useTheme} from "@mediaspace/shared/styled";
import { Tooltip } from "@kaltura/ds-react-components";
import { useContext } from "react";
import { ConfigContext, defaultContext } from "@mediaspace/shared/data-kms-config";

export interface RowActionsProps extends Pick<FileRowProps, "isOpen" | "onCancel" | "onPublish" | "onEdit" | "onRemove">, Omit<FileMetaData, "progress"> {
    entryId?: string,
    showEditButton?: boolean,
    showRemoveButton?: boolean
}

const RowActions = ({
    tokenId,
    entryId,
    publishStatus,
    status,
    isOpen,
    onCancel,
    onPublish,
    onEdit,
    onRemove,
    showEditButton,
    showRemoveButton
}: RowActionsProps)  => {
    const theme = useTheme();
    const context = useContext(ConfigContext) || defaultContext;

    const isXsScreen = useMediaQuery(
        theme.breakpoints.down(theme.breakpoints.values.sm)
    );

    const getPublishStatus = (publishStatus: PublishStatus) => {
        switch (publishStatus) {
            case PublishStatus.UNLISTED:
                return translate("Unlisted");
            case PublishStatus.PUBLISHED:
                return translate("Published");
        }
        return translate("Private");
    };

    const getPublishIcon = (publishStatus: PublishStatus) => {
        switch (publishStatus) {
            case PublishStatus.UNLISTED:
                return <Link24Icon/>;
            case PublishStatus.PUBLISHED:
                return <Globe24Icon/>;
        }
        return <Lock24Icon/>;
    };

    const isKaf = !!context.application.isKAF;

    return (
        <StyledRowActions className={"kms-ds-upload-media-file-row-actions"}>
            {(status === UploadStatus.IN_QUEUE || status === UploadStatus.UPLOADING) &&
                (<ChangingButton icon={<Clear24Icon/>} onClick={() => onCancel(tokenId)} label={translate("Cancel Upload")} />)
            }
            {(entryId && (status === UploadStatus.READY || status === UploadStatus.PROCESSING) ) && !isKaf &&
                (<ChangingButton icon={<ExternalLink24Icon/>} href={getEntryUrl(entryId)} target={"_blank"} label={translate("Media Page")} />)
            }
            {!isXsScreen && (status !== UploadStatus.UPLOAD_FAILED && status !== UploadStatus.ENTRY_FAILED && status !== UploadStatus.CANCELED && status !== UploadStatus.PRE_QUEUE) &&
                (<Tooltip title={translate("Coming Soon")} placement={"top"} PopperProps={TooltipPopperProps} describeChild={true}>
                    <span>
                        <ChangingButton icon={getPublishIcon(publishStatus)} onClick={() => onPublish(tokenId)} label={getPublishStatus(publishStatus)} disabled={true}/>
                    </span>
                </Tooltip>)
            }
            {(showEditButton && status !== UploadStatus.UPLOAD_FAILED && status !== UploadStatus.ENTRY_FAILED && status !== UploadStatus.CANCELED && status !== UploadStatus.PRE_QUEUE) &&
                (<ChangingButton icon={<Edit24Icon/>} onClick={() => onEdit(tokenId)} selected={isOpen} label={translate("Edit")}/>)
            }
            {showRemoveButton && (status === UploadStatus.UPLOAD_FAILED || status === UploadStatus.ENTRY_FAILED || status === UploadStatus.CANCELED) &&
                (<ChangingButton icon={<Clear24Icon/>} onClick={() => onRemove(tokenId)} label={translate("Remove")} />)
            }
        </StyledRowActions>
    );
};
export default RowActions;
