import { ReactNode } from "react";
import { Tooltip, TooltipProps } from "@kaltura/ds-react-components";
import { useElementSizeObserver } from "@mediaspace/hooks";
import styled from "@emotion/styled";

export interface TruncatedLineProps {
    className?: string;
    tooltipPlacement?: TooltipProps["placement"];
    children: ReactNode;
}

/**
 * The component shows one line of text.
 * If the contents exceed the container, the component truncates it with ellipsis and shows a tooltip with the full text.
 *
 * Note: the children of the component should have no styling applied to them (because they are also used as a tooltip contents).
 * If the text needs to be styled, the styled should be either applied to the TruncatedLine component directly
 * or inherited from the parent DOM element. See examples below:
 *
 * Bad:
 * <TruncatedLine>
 *     <StyledTypography>Text</StyledTypography>
 * </TruncatedLine>
 *
 * Good:
 * <StyledTruncatedLine>
 *     Text
 * </StyledTruncatedLine>
 *
 * Even better:
 * <StyledTypography>
 *     <TruncatedLine>
 *         Text
 *     </TruncatedLine>
 * </StyledTypography>
 */
export const TruncatedLine = ({className, tooltipPlacement = "top", children}: TruncatedLineProps) => {
    const containerSizeTracker = useElementSizeObserver();
    const contentSizeTracker = useElementSizeObserver();

    const contentExceedsContainer = containerSizeTracker.width && contentSizeTracker.width && contentSizeTracker.width > containerSizeTracker.width;

    // Show the tooltip only when the contents exceed the container (empty title prop will disable the tooltip functionality)
    return <Tooltip title={contentExceedsContainer ? children : ""} placement={tooltipPlacement} describeChild={true}>
        <StyledOneLiner className={className} ref={containerSizeTracker.elementRef}>
            <span ref={contentSizeTracker.elementRef}>{children}</span>
        </StyledOneLiner>
    </Tooltip>;
};

const StyledOneLiner = styled("div")({
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
});
