import { FunctionComponent, MouseEvent, useCallback } from "react";
import { composeClasses, useTheme } from "@mediaspace/shared/styled";
import {
    translate,
    useMediaQuery,
} from "@mediaspace/shared/utils";
import { HeaderLogo, HeaderLogoProps } from "@mediaspace/header-menu";
import { FooterClasses, getFooterClass } from "./footerClasses";
import { IconButton } from "@kaltura/ds-react-components";
import {
    StyledBottomContainer,
    StyledBottomContent,
    StyledBottomText,
    StyledFooter,
    StyledFooterContainer,
    StyledFooterContent,
    StyledFooterWrapper,
    StyledLink,
    StyledLinkContainer,
    StyledLinks,
    StyledLinksAndTextContainer,
    StyledLinkWrapper,
    StyledPromotionButton,
    StyledPromotionContent,
    StyledPromotionHeader, StyledSeparator,
    StyledSocialLinksList,
    StyledTypography,
    StyledUpperContainer
} from "./styledComponents";
import SocialLinks from "./social-links/SocialLinks";
import clsx from "clsx";
import {Truncate} from "@mediaspace/shared/ui";
import {TruncateWithTooltip} from "@mediaspace/shared/ui";
import {useButtonAnalytics} from "@mediaspace/hooks";
import {ButtonClickAnalyticsType} from "@mediaspace/shared/types/ButtonClickAnalyticsType";
import ReactHtmlParser from "@mediaspace/shared/ui/html-react-parser";
import styled from "@emotion/styled";

export enum Social {
    twitter = "twitter",
    facebook = "facebook",
    linkedin = "linkedin",
    youtube = "youtube",
    instagram = "instagram",
    twitch = "twitch",
    slack = "slack",
    tiktok = "tiktok",
    line = "line",
}
export type SocialType = keyof typeof Social;

enum SocialTranslateKey {
    twitter = "Follow us on Twitter",
    facebook = "Follow us on Facebook",
    linkedin = "Connect with us on LinkedIn",
    youtube = "Subscribe to our YouTube Channel",
    instagram = "Follow us on Instagram",
    twitch = "Watch us on Twitch",
    slack = "Follow us on Slack",
    tiktok = "Follow us on tiktok",
    line = "Follow us on line",
}
interface PromotionSection {
    header?: string;
    text?: string;
    buttonText?: string;
    buttonUrl?: string;
}
interface SocialLink {
    url?: string;
    type: SocialType;
}

export enum Content {
    URL = "URL",
    Script = "Script",
}
export type LinkType = keyof typeof Content;

interface TextAndLink {
    sectionType: LinkType;
    sectionText?: string;
    sectionLink: string;
}

export interface FooterProps {
    backgroundColor: string;
    backgroundUrl?: string;
    promotion: PromotionSection;
    headerLogo: HeaderLogoProps;
    socialLinks?: SocialLink[];
    textAndLinks?: TextAndLink[];
    linkSeparator?: string;
    bottomText?: string;
    classes?: Partial<FooterClasses>;
}

// Update StyledLink styling to support tooltip being displayed for truncated footer links when zoomed in (ADA-498)
const ModifiedStyledLink = styled(StyledLink)({
    whiteSpace: "normal",
    textOverflow: "clip",
});

const useUtilityClasses = (styleProps: Partial<FooterProps>) => {
    const { classes } = styleProps;

    const slots = {
        root: ["root"],
        image: ["image"],
        logo: ["logo"],
        promotion: ["promotion"],
        title: ["title"],
        text: ["text"],
        button: ["button"],
        bottomText: ["bottomText"],
        link: ["link"],
        socialIcon: ["socialIcon"],
        socialLinks: ["socialLinks"],
        content: ["content"],
        bottomContainer: ["bottomContainer"],
        bottomContent: ["bottomContent"],
        textAndLinks: ["textAndLinks"],
        separator: ["separator"],
        linkWrapper: ["linkWrapper"],
    };

    return composeClasses(slots, getFooterClass, classes);
};


export const Footer: FunctionComponent<FooterProps> = ({
    backgroundColor,
    backgroundUrl,
    promotion,
    headerLogo,
    socialLinks,
    textAndLinks,
    linkSeparator,
    bottomText,
    classes: classesProp,
}) => {
    const theme = useTheme();
    const isSmScreen = useMediaQuery(
        theme.breakpoints.down(theme.breakpoints.values.md)
    );

    const classes = useUtilityClasses({ classes: classesProp });

    const sendButtonAnalytics = useButtonAnalytics();

    const sendAnalytics = (actionName: string, shouldEval = false) => {
        sendButtonAnalytics(actionName, shouldEval ? ButtonClickAnalyticsType.LAUNCH : ButtonClickAnalyticsType.NAVIGATE);
    }

    const getSocialLinks = useCallback(() => {
        return socialLinks?.map((socialLink, index) => {
            return (
                <li key={index}>
                    <IconButton
                        className={clsx(classes.socialIcon, socialLink.type)}
                        key={socialLink.url + socialLink.type}
                        component={"a"}
                        href={socialLink.url}
                        title={translate(SocialTranslateKey[socialLink.type])}
                        target={"_blank"}
                        rel={"noopener noreferrer"}
                        onClick={() => {
                            sendAnalytics(`Footer - Social Link - ${socialLink.type}`);
                        }}
                    >
                        <SocialLinks linkType={socialLink.type} />
                    </IconButton>
                </li>
            );
        });
    }, [classes.socialIcon, socialLinks]);

    const getTextAndLinks = useCallback(() => {
        return textAndLinks?.map((textAndLink, index) => {
            if (textAndLink.sectionLink.length > 0) {
                switch (textAndLink.sectionType) {
                    case "Script":
                        return (
                            <StyledLinkWrapper key={index} className={classes.linkWrapper}>
                                {index > 0 && (
                                    <StyledSeparator className={classes.separator}>
                                        {linkSeparator}
                                    </StyledSeparator>
                                )}
                                <StyledLinkContainer>
                                    <TruncateWithTooltip text={textAndLink.sectionText}>
                                        <ModifiedStyledLink
                                            className={classes.link}
                                            inText={false}
                                            onClick={() => {
                                                eval(textAndLink.sectionLink);
                                                sendAnalytics("Footer - Links - JS", true);
                                            }}
                                            component={"button"}
                                            variant={"body2"}
                                            aria-label={textAndLink.sectionText}
                                        >
                                            {textAndLink.sectionText}
                                        </ModifiedStyledLink>
                                    </TruncateWithTooltip>
                                </StyledLinkContainer>
                            </StyledLinkWrapper>
                        );
                    case "URL":
                        return (
                            <StyledLinkWrapper key={index} className={classes.linkWrapper}>
                                {index > 0 && (
                                    <StyledSeparator className={classes.separator}>
                                        {linkSeparator}
                                    </StyledSeparator>
                                )}
                                <StyledLinkContainer>
                                    <TruncateWithTooltip text={textAndLink.sectionText}>
                                            <ModifiedStyledLink
                                                className={classes.link}
                                                inText={false}
                                                href={textAndLink.sectionLink}
                                                target={"_blank"}
                                                rel={"noopener noreferrer"}
                                                onClick={() => {
                                                    sendAnalytics("Footer - Links - URL");
                                                }}
                                                aria-label={textAndLink.sectionText}
                                            >
                                                {textAndLink.sectionText}
                                            </ModifiedStyledLink>
                                    </TruncateWithTooltip>
                                </StyledLinkContainer>
                            </StyledLinkWrapper>
                        );
                    default:
                    // never should happen
                    return null;
                }
            } else {
                return (
                    <StyledLinkWrapper key={index} className={classes.linkWrapper}>
                        {index > 0 && (
                            <StyledSeparator className={classes.separator}>{linkSeparator}</StyledSeparator>
                        )}
                        <StyledLinkContainer>
                            <TruncateWithTooltip text={textAndLink.sectionText}>
                                <ModifiedStyledLink
                                    className={classes.link}
                                    inText={false}
                                    onClick={(e: MouseEvent<HTMLElement>) =>
                                        e.preventDefault()
                                    }
                                    target={"_blank"}
                                >
                                {textAndLink.sectionText}
                                </ModifiedStyledLink>
                            </TruncateWithTooltip>
                        </StyledLinkContainer>
                    </StyledLinkWrapper>
                );
            }
        });
    }, [textAndLinks, linkSeparator]);

    return (
        <StyledFooterWrapper className={classes.root}>
            <StyledFooterContainer
                className={classes.image}
                url={backgroundUrl}
                color={backgroundColor}
            >
                <StyledFooter >
                    <StyledFooterContent className={classes.content}>
                        {promotion && (
                            <StyledUpperContainer className={classes.promotion}>
                                {promotion.header && (
                                    <StyledPromotionHeader
                                        className={classes.title}
                                    >
                                        <StyledTypography
                                            variant={isSmScreen ? "h2" : "h1"}
                                        >
                                            <Truncate
                                                lines={3}
                                                showMore={false}
                                            >
                                                {promotion.header}
                                            </Truncate>
                                        </StyledTypography>
                                    </StyledPromotionHeader>
                                )}
                                <StyledPromotionContent className={classes.text} >
                                    {promotion.text && (
                                        <StyledTypography variant="body1">
                                            {ReactHtmlParser(promotion.text)}
                                        </StyledTypography>
                                    )}
                                    {promotion.buttonText && (
                                        <div>
                                            <StyledPromotionButton
                                                className={classes.button}
                                                onClick={() => {
                                                    sendAnalytics("Footer - Promotion Link");
                                                }}
                                                {...(promotion.buttonUrl ? {href: promotion.buttonUrl, target: "_blank"} : {})}
                                            >
                                                {promotion.buttonText}
                                            </StyledPromotionButton>
                                        </div>
                                    )}
                                </StyledPromotionContent>
                            </StyledUpperContainer>
                        )}
                        <StyledBottomContainer className={classes.bottomContainer}>
                            {!!headerLogo && (
                                <HeaderLogo
                                    {...headerLogo}
                                    className={classes.logo}
                                />
                            )}
                            <StyledBottomContent
                                className={classes.bottomContent}
                                headerLogoExist={!!headerLogo}
                            >
                                <StyledLinksAndTextContainer className={classes.textAndLinks}>
                                    <StyledLinks>
                                        {!!textAndLinks && getTextAndLinks()}
                                    </StyledLinks>
                                    <StyledBottomText
                                        variant="body2"
                                        className={classes.bottomText}
                                    >
                                        {bottomText}
                                    </StyledBottomText>
                                </StyledLinksAndTextContainer>
                                    <StyledSocialLinksList className={classes.socialLinks}>
                                        {!!socialLinks && getSocialLinks()}
                                    </StyledSocialLinksList>
                            </StyledBottomContent>
                        </StyledBottomContainer>
                    </StyledFooterContent>
                </StyledFooter>
            </StyledFooterContainer>
        </StyledFooterWrapper>
    );
};
