import { ComponentType, KeyboardEvent, useEffect, useState } from "react";
import styled from "@emotion/styled";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import clsx from "clsx";
import { translate, useMediaQuery } from "@mediaspace/shared/utils";
import { ChevronLeft24Icon, ChevronRight24Icon } from "@kaltura/ds-react-icons";
import { alpha, Box, composeClasses, systemWidth, useTheme } from "@mediaspace/shared/styled";
import { CarouselClasses, getCarouselClass } from "./carouselClasses";
import { CustomArrow } from "./custom-arrow/CustomArrow";

type ItemProps<ItemT> = {
    item: ItemT;
    currentCardsNumberInSlides: 2 | 3 | 4 | 5;
    minPossibleCardsNumberInSlides: 2 | 3 | 4 | 5;
    onResize?: (height: number) => void;
};

type Props<ItemT, PropsT> = {
    active?: boolean;
    items: ItemT[];
    currentCardsNumberInSlides: 2 | 3 | 4 | 5; // amount of slides on the screen for large devices ("xl" and "lg")
    mdCardsNumberInSlides?: number; // amount of slides on the screen for medium devices ("md")
    minPossibleCardsNumberInSlides?: 2 | 3 | 4 | 5; // the smallest number of slides this carousel shows
    className?: string;
    itemComponent: ComponentType<ItemProps<ItemT> & PropsT>;
    itemProps?: PropsT | ((item: ItemT, index: number) => PropsT);
    classes?: Partial<CarouselClasses>;
    activeModal?: string;
    setActiveModal?: (value: string) => void;
    container?: string; // analytics action name depends on the container component of the carousel (e.g: "News" / "Playlist" ...)
    fullScreenWidth?: boolean;
};

const useUtilityClasses = (styleProps: Partial<Props<any, any>>) => {
    const { classes } = styleProps;

    const slots = {
        root: ["root"],
        item: ["item"],
        arrow: ["arrow"],
    };

    return composeClasses(slots, getCarouselClass, classes);
};



export const slickClasses = {
    slide: "slick-slide",
    list: "slick-list",
    arrow: "slick-arrow",
    next: "slick-next",
    prev: "slick-prev",
    disabled: "slick-disabled",
    track: "slick-track",
    regular: "regular",
    oneSlide: "one-slide",
};



const StyledContainer = styled("div")<{ gap: number }>(({ theme, gap }) => ({
    /* override carousel style to match design */
    position: "relative",
    paddingRight: theme.spacing(gap),
}));

const StyledSliderContainer = styled(Box)({
    "&:hover": {
        [`.${slickClasses.arrow}`]: {
            opacity: 1,
            zIndex: 2
        },
    },
});

const StyledSlider = styled(Slider, {
    shouldForwardProp(propName: PropertyKey): boolean {
        return propName !== "fullScreenWidth" && propName !== "useSpacing" && propName !== "imageHeight" && propName !== "gap";
    }
})<{ fullScreenWidth?: boolean; useSpacing?: boolean; imageHeight?: number; gap: number }>((
    { theme, fullScreenWidth, useSpacing, imageHeight, gap }
) => [
    {
        // styles applied to root element
        // desktops will have a scroll bar, and are less relevant in these sizes
        ["max-device-size: " + (theme.breakpoints.values.md - 0.05)]: {
            overflowX: "hidden",
            msOverflowX: "hidden",
        },
        // styles applied to slide
        [`.${slickClasses.slide}`]: {
            width: 276,
        },
        // styles applied to slide list
        [`.${slickClasses.list}`]: {
            marginRight: theme.spacing(-gap),
        },
        // styles applied to arrow
        [`.${slickClasses.arrow}`]: {
            zIndex: 10,
            height: "auto",
            ...(!!imageHeight && {
                top: imageHeight / 2,
            }),
        },
        // styles applied to next arrow
        [`.${slickClasses.next}`]: {
            right: fullScreenWidth ? (useSpacing ? theme.spacing(5) : theme.spacing(3)) : -20,
        },
        // styles applied to prev arrow
        [`.${slickClasses.prev}`]: {
            left: fullScreenWidth ? 0 : -36,
        },
        // styles applied to arrow (hide third party arrow)
        [`.${slickClasses.arrow}:before`]: {
            content: "none",
        },
        [theme.breakpoints.up("lg")]: {
            // styles applied to next arrow
            [`.${slickClasses.next}`]: {
                right: fullScreenWidth ? (useSpacing ? theme.spacing(5) : theme.spacing(3)) : -20,
            },
            // styles applied to prev arrow
            [`.${slickClasses.prev}`]: {
                left: fullScreenWidth ? 0 : -48,
            },
        },
    },
    fullScreenWidth && {
        overflow: "hidden",
        [`.${slickClasses.arrow}`]: {
            opacity: 0
        },
        [`.${slickClasses.track}`]: {
            left: useSpacing ? theme.spacing(2) : theme.spacing(0)
        }
    },
    !fullScreenWidth && systemWidth({ theme }),
]);

const StyledUnSlider = styled(Box, {
    shouldForwardProp(propName: PropertyKey): boolean {
        return propName !== "fullScreenWidth";
    }
})<{ fullScreenWidth?: boolean }>(({ theme, fullScreenWidth }) => [
    fullScreenWidth && {
        overflow: "hidden",
    },
    {
        whiteSpace: "nowrap",
        overflowX: "auto",
        msOverflowX: "auto",
        scrollbarWidth: "none",
        [StyledContainer as any]: {
            display: "inline-block",
            verticalAlign: "top",
        },
        [`&::-webkit-scrollbar`]: {
            display: "none",
        },

        // desktops will have a scroll bar, and are less relevant in these sizes
        ["max-device-size: " + (theme.breakpoints.values.md - 0.05)]: {
            overflowX: "hidden",
            msOverflowX: "hidden",
        },
    },
]);

const StyledUnSliderInner = styled(Box, {
    shouldForwardProp(propName: PropertyKey): boolean {
        return propName !== "fullScreenWidth" && propName !== "slidesCount" && propName !== "gap";
    }
})<{ fullScreenWidth?: boolean; slidesCount: number; gap: number }>(({ theme, fullScreenWidth, slidesCount, gap }) => [
    !fullScreenWidth && systemWidth({ theme }),
    {
        // inline display is required for scrolling the carousel track on mobile
        display: "inline-flex",
        gap: theme.spacing(gap),
        [theme.breakpoints.up("md")]: {
            // block display is required for applying system width correctly on desktop
            display: "flex",
            justifyContent: "center",
            [StyledContainer as any]: {
                width: `calc((100% - ${theme.spacing(gap * (slidesCount - 1))}) / ${slidesCount})`,
            },
        },
    },
]);



enum Side {
    left = 'left',
    right = 'right'
}

const StyledGradient = styled(Box, {
    shouldForwardProp(propName: PropertyKey): boolean {
        return propName !== "height" && propName !== "side";
    }
})<{ height: number; side: Side }>(
    ({ theme, height, side }) => ({
        width: 100,
        height: height,
        backgroundImage: `linear-gradient(${side === Side.left ? "90deg" : "270deg" }, ${alpha(theme.kaltura.palette.surfaces.background, 0.8)} 0%, ${alpha(theme.kaltura.palette.surfaces.background, 0)} 100%)`,
        position: "absolute",
        top: 0,
        zIndex: 1,
        ...(side === Side.left && {
            left: 0
        }),
        ...(side === Side.right && {
            right: 0
        })
    })
);

/**
 * Generic component for carousel. The component handle carousels of 2-4 cards.
 */
export const Carousel = <ItemT, PropsT>({
    items,
    currentCardsNumberInSlides = 4,
    mdCardsNumberInSlides = Math.min(currentCardsNumberInSlides, 3),
    minPossibleCardsNumberInSlides = currentCardsNumberInSlides,
    className,
    itemProps,
    itemComponent: ItemComponent,
    activeModal = "",
    setActiveModal,
    container,
    fullScreenWidth = false,
    ...rest
}: Props<ItemT, PropsT>) => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [isSlidingWithTab, setIsSlidingWithTab] = useState(false);
    const classes = useUtilityClasses(rest);
    const numberOfSlides = items.length - (currentCardsNumberInSlides - 1);
    const prevDisabled = currentSlideIndex === 0;
    const theme = useTheme();
    const [useSpacing, setUseSpacing] = useState<boolean>(true);
    const [tabIndexArray, setTabIndexArray] = useState<number[]>([]);
    const [currentSlide, setCurrentSlide] = useState(0);


    const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
    const isDownLg = useMediaQuery(theme.breakpoints.down("lg"));

    const gap = isDownLg ? 2 : 4;


    /**
     * Updates the tab index array to manage focusable elements within a carousel.
     *
     * This function calculates which items should be focusable (tab index of 0) based on the current slide index.
     * @param {number} slideIndex - The index of the currently active or selected slide in the carousel.
     */
    const handleTabIndexChange = (slideIndex: number) => {
        // Initialize an array for tab indices with all elements set to -1 (not focusable)
        const initialTabIndexArray = Array<number>(items.length).fill(-1);
        // Calculate the start index of focusable items based on the active slide index and number of items per slide
        let startIndex = slideIndex * currentCardsNumberInSlides;
         // Determine the end index for focusable items, ensuring it does not exceed the total number of items
        const endIndex = Math.min(startIndex + currentCardsNumberInSlides, items.length);
        // If the end index reaches the last item, adjust the start index to include exactly 'currentCardsNumberInSlides' items
        if(endIndex === items.length) {
            startIndex = items.length - currentCardsNumberInSlides
        }

        for (let i = startIndex; i < endIndex; i++) {
            // Set the tab index of each item within the current slide to 0 (focusable)
            initialTabIndexArray[i] = 0;
        }
         // Update the state with the new array of tab indices
        setTabIndexArray(initialTabIndexArray);
    }

    useEffect(() => {
        handleTabIndexChange(0);
    }, [])

    /*
     * The behavior of the "next slide" arrow button is different between
     * navigating the items with the arrow buttons and navigating the items with "tab".
     *
     * When navigating with the arrow buttons, the "next slide" button
     * should be disabled when reaching the last __page__.
     *
     * When navigating with "tab", the "next slide" button
     * should be disabled when reaching the last __item__.
     */
    const reachedEndOfSlides = isSlidingWithTab
        ? currentSlideIndex === items.length - 1
        : currentSlideIndex >= numberOfSlides - 1;

    const beforeSlideChange = (oldIndex: number, newIndex: number) => {
        setCurrentSlideIndex(newIndex);
        setIsSlidingWithTab(false);
    };

    const handleItemFocus = (index: number) => {
        setCurrentSlideIndex(index);
        setIsSlidingWithTab(true);
    };

    const handleSliding = () => {
        setUseSpacing(false)
    }

    const handleKeyDownRight = (e: KeyboardEvent) => {
        if(e.key === "Enter") {
            if (!reachedEndOfSlides) {
                handleTabIndexChange(currentSlide + 1);
                setCurrentSlide(currentSlide + 1);
            }
        }
    }

    const handleKeyDownLeft = (e: KeyboardEvent) => {
        if(e.key === "Enter") {
            if (!prevDisabled) {
                handleTabIndexChange(currentSlide - 1);
                setCurrentSlide(currentSlide - 1);
            }
        }
    }

    const finalSlidesCount = isDownLg ? mdCardsNumberInSlides : currentCardsNumberInSlides;
    const settings: Settings = {
        slidesToShow: finalSlidesCount,
        slidesToScroll: finalSlidesCount,
        infinite: false,
        nextArrow: (
            <CustomArrow
                iconClassName={classes.arrow}
                disabled={reachedEndOfSlides}
                icon={<ChevronRight24Icon />}
                ariaLabel={translate("next slide arrow")}
                position={"Right"}
                container={container}
                fullScreenWidth={fullScreenWidth}
                handleKeyDown={handleKeyDownRight}
            />
        ),
        prevArrow: (
            <CustomArrow
                iconClassName={classes.arrow}
                disabled={prevDisabled}
                icon={<ChevronLeft24Icon />}
                ariaLabel={translate("previous slide arrow")}
                position={"Left"}
                container={container}
                fullScreenWidth={fullScreenWidth}
                handleKeyDown={handleKeyDownLeft}
            />
        ),
        beforeChange: beforeSlideChange,
        afterChange: handleSliding
    };

    const onThumbnailResize = (height: number) => {
        setImageHeight(height);
    };

    const [imageHeight, setImageHeight] = useState(0);

    const unslick = isDownMd || items.length <= finalSlidesCount;

    const renderedItems = items.map((item, index) => {
        return (
            <StyledContainer
                key={index}
                className={classes.item}
                gap={unslick ? 0 : gap}
                onFocus={() => handleItemFocus(index)}

            >
                <ItemComponent
                    item={item}
                    tabIndex={tabIndexArray[index]}
                    currentCardsNumberInSlides={currentCardsNumberInSlides}
                    minPossibleCardsNumberInSlides={
                        minPossibleCardsNumberInSlides
                    }
                    activeModal={activeModal}
                    setActiveModal={setActiveModal}
                    onThumbnailResize={onThumbnailResize}
                    {...(typeof itemProps === "function" ? (itemProps as (item: ItemT, index: number) => PropsT)(item, index) : itemProps!)}
                />
            </StyledContainer>
        )
    });

    return (
        <StyledSliderContainer>
            {fullScreenWidth && numberOfSlides > 0 && !useSpacing && <StyledGradient height={imageHeight} side={Side.left} />}
            {!unslick && <StyledSlider
                className={clsx(className, classes.root)}
                // react-slider can't update properly when changing the slides count, so re-render it completely
                key={finalSlidesCount}
                fullScreenWidth={fullScreenWidth}
                useSpacing={useSpacing}
                imageHeight={imageHeight}
                gap={gap}
                {...settings}
            >
                {renderedItems}
            </StyledSlider>}
            {unslick && <StyledUnSlider
                className={clsx(className, classes.root, slickClasses.regular)}
                fullScreenWidth={fullScreenWidth}
            >
                <StyledUnSliderInner
                    fullScreenWidth={fullScreenWidth}
                    slidesCount={finalSlidesCount}
                    gap={gap}
                >
                    {renderedItems}
                </StyledUnSliderInner>
            </StyledUnSlider>}
            {fullScreenWidth && numberOfSlides > 0 && <StyledGradient height={imageHeight} side={Side.right} />}
        </StyledSliderContainer>
    );
};
