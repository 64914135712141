import React, {useRef} from "react";
import { motion } from "framer-motion";
import clsx from "clsx";
import { IconButton } from "@kaltura/ds-react-components";
import { translate } from "@mediaspace/shared/utils";
import { ChevronRight24Icon } from "@kaltura/ds-react-icons";
import styled from "@emotion/styled";
import { SidebarMenuItem } from "@mediaspace/shared/types/SidebarMenuItem";
import {useButtonAnalytics} from "@mediaspace/hooks";
import {ButtonClickAnalyticsType} from "@mediaspace/shared/types/ButtonClickAnalyticsType";

export type SidebarItemProps = {
    menuItem: SidebarMenuItem;
    handleClickSubMenuIcon: (menuItem: SidebarMenuItem) => void;
    handleKeyDownSubMenuIcon: (
        e: React.KeyboardEvent<HTMLButtonElement>,
        menuItem: SidebarMenuItem
    ) => void;
    index: number;
    keyDownItemHandler: (
        e: React.KeyboardEvent<HTMLElement>,
        index: number,
        menuItem: SidebarMenuItem
    ) => void;
    isSidebarOpen: boolean;
};

const StyledLi = styled(motion.li)(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 320,
    minHeight: 56,
    padding: `0 ${theme.spacing(3)} 0 0`,
    [`&.active`]: {
        backgroundColor: theme.kaltura.palette.tone7,
    },
    [`&:hover`]: {
        backgroundColor: theme.kaltura.palette.tone7,
    },
}));

const StyledDiv = styled.div({
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width:'100%'
});

const StyledA = styled.a(({ theme }: { theme?: any }) => {
    return {
        flexGrow: 1,
        textDecoration: "none",
        fontSize: theme.typography.htmlFontSize,
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: theme.typography.pxToRem(19),
        /* Tone (Light Theme)/Tone 1 */
        color: theme.kaltura.palette.tone1,
        padding: `${theme.spacing(3)} 0 ${theme.spacing(3)} ${theme.spacing(3)}`,
        // override eventsplatform css
        ".eventsplatform &": {
            fontSize: theme.typography.htmlFontSize,
            lineHeight: theme.typography.pxToRem(19),
            color: theme.kaltura.palette.tone1,
            "&:hover": {
                color: theme.kaltura.palette.tone1,
            },
        },
        // override v2ui and bootstrap
        [`a&:hover, a&:focus`]: {
            color: theme.kaltura.palette.tone1,
            textDecoration: "none",
        },
    };
});

const MoreButton = styled(IconButton)(({ theme }) => ({
    height: theme.typography.pxToRem(19),
    color: theme.kaltura.palette.tone1,
    [`&:hover`]: {
        backgroundColor: "initial",
    },
}));

/*
 * Sidebar Menu Item component
 */
const SidebarItem: React.FC<SidebarItemProps> = ({
    menuItem,
    handleClickSubMenuIcon,
    handleKeyDownSubMenuIcon,
    index,
    keyDownItemHandler,
    isSidebarOpen,
}) => {
    const { label, target, uri, pages, class: noLink, active, buttonAnalyticsData } = menuItem;
    const NOLINK = "navmenu-nolink";
    const tabIndex = !isSidebarOpen ? -1 : noLink === NOLINK ? -1 : 0;
    const linkRef = useRef<HTMLAnchorElement>(null);
    const sendButtonAnalytics = useButtonAnalytics();
    const handleKeyDownItemContainer:React.KeyboardEventHandler<HTMLDivElement> = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (!['Enter', ' '].includes(e.key)) return;
        if (!linkRef.current) return;

        linkRef.current.click()
    };
    return (
        <StyledLi
            onKeyDown={(e) => keyDownItemHandler(e, index, menuItem)}
            className={clsx("kms-ds-sidebar-menu-item", active && "active")}
            role="presentation"
            initial={{ x: "-100%" }}
            animate={{
                x: 0,
                transition: { ease: "easeOut", duration: 0.4 },
            }}
        >
            <StyledDiv role="menuitem" tabIndex={tabIndex} onKeyDown={handleKeyDownItemContainer}>
                <StyledA
                    ref={linkRef}
                    aria-label={translate(label)}
                    target={target ? target : undefined}
                    href={uri}
                    // if noLink === NOLINK (true) menuItem is not clickable,
                    // but if menuItem has subMenu after click on menuItem subMenu will be opened
                    onClick={() => {
                        if (buttonAnalyticsData?.analyticsValue) {
                            sendButtonAnalytics(buttonAnalyticsData.analyticsValue, ButtonClickAnalyticsType.NAVIGATE);
                        }
                        noLink === NOLINK && menuItem.pages?.length && handleClickSubMenuIcon(menuItem);
                    }}
                >
                    {translate(label)}
                </StyledA>
                {pages && pages.length ? (
                    <MoreButton
                        tabIndex={!isSidebarOpen ? -1 : 0}
                        aria-label={translate("Open sub menu %1", [label])}
                        onClick={() => handleClickSubMenuIcon(menuItem)}
                        onKeyDown={(e) => handleKeyDownSubMenuIcon(e, menuItem)}
                    >
                        <ChevronRight24Icon />
                    </MoreButton>
                ) : null}
            </StyledDiv>
        </StyledLi>
    );
};

export default SidebarItem;
