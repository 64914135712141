import styled from "@emotion/styled";
import Truncate from "../truncate/Truncate";
// noinspection ES6PreferShortImport
import { Thumbnail } from "../thumbnail/Thumbnail";

export const ItemDetailsWrapper = styled.div<{slidesCount: number}>(({ theme, slidesCount }) => [
    {
        padding: theme.spacing(2),
        height: 64,
    },
    // Make the details area height bigger on desktop when there are only 2 carousel items per row,
    // because the items are large enough to allow that.
    slidesCount === 2 && {
        [theme.breakpoints.up("md")]: {
            height: 92,
        },
    }
]);

export const ItemLink = styled.a({
    display: "block",
    textDecoration: "none",
    height: "100%",
    width: "100%",
    whiteSpace: "normal" // override what the slick thing is doing so truncation will work
});

export const StatusTag = styled.span(({ theme }) => ({
    textTransform: "uppercase",
    fontSize: theme.typography.pxToRem(11),
    fontWeight: theme.kaltura.typography.fontWeightBold,
    color: theme.kaltura.palette.tone8,
    backgroundColor: theme.kaltura.palette.danger.main,
    borderRadius: theme.kaltura.shape.roundness1,
    display: "inline-block",
    lineHeight: "normal",
    padding: "2px 3px",
}));

export const Data = styled.span(({ theme }) => ({
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.kaltura.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
}));

export const DataContainer = styled.div(({ theme }) => ({
    marginTop: 6,
    color: theme.kaltura.palette.tone2,
    fontSize: theme.typography.pxToRem(12),
}));

export const StyledTruncatedDescription = styled(Truncate)(
    ({ theme }) => ({
        marginTop: theme.spacing(0.5),
        fontSize: theme.kaltura.typography.fontSize,
        color: theme.kaltura.palette.tone2,
        lineHeight: theme.typography.pxToRem(19),
        fontWeight: theme.kaltura.typography.fontWeightRegular,
        width: "100%"
    })
);

export const StyledTruncatedName = styled(Truncate)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.kaltura.typography.fontWeightBold,
    color: theme.kaltura.palette.tone1,
    lineHeight: theme.typography.pxToRem(16),
    width: "100%"
}));

// Container of 16:9 ratio for the thumbnail
export const StyledMediaImage = styled.div({
    position: "relative",
    width: "100%",
    height: 0,
    paddingTop: "56.25%",
});

// Thumbnail that takes the whole size of the container
export const StyledThumbnail = styled(Thumbnail)({
    position: "absolute",
    inset: 0,
    width: "100%",
    height: "100%",
});
