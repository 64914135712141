import styled from "@emotion/styled";
import {motion} from "framer-motion";
import {KeyboardEvent, useRef, useEffect, useReducer} from "react";
import Menu from "./Menu/Menu";
import {
    CloseSubMenuAction, FocusItem,
    initialState,
    OpenSubMenuAction,
    reducer, ToggleSidebarAction,
} from "./SidebarReducer";
import {SidebarMenuItem} from "@mediaspace/shared/types/SidebarMenuItem";
import {useMediaQuery} from "@mediaspace/shared/utils";
import {useTheme} from "@mediaspace/shared/styled";

const StyledNav = styled(motion.nav)({
    position: "fixed",
    left: 0,
    zIndex: 1000,
    top: 0,
});

/**
 * overlay allows closing the menu when clicking anywhere else on page
 */
const Overlay = styled.div({
    position: "fixed",
    width: "100%",
    minHeight: "100%",
    background: "transparent",
    zIndex: 998,
    top: 0,
    left: 0,
});

const variants = {
    open: () => ({
        opacity: 1,
        x: 0,
        transition: { ease: "easeOut", duration: 0.4 },
    }),
    closed: {
        opacity: 0,
        x: "-120%",
        transition: { ease: "easeOut", duration: 0.4 },
    },
};

export type NavigationPanelProps = {
    pages: SidebarMenuItem[];
    topLinks: SidebarMenuItem[];
};

/**
 * navigation panel - this is the side navigation menu component, separated from the
 * header menu toggle sidebar button. this component is not a direct child of the header menu
 * because it's being rendered in the page's floating layer, in order to be located on top of
 * the bottom CnC panel (on mobile), which is also located in the floating layer.
 */
const NavigationPanel = (props: NavigationPanelProps) => {

    const {pages, topLinks} = props;

    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
    const navigationPanelMenuItems = smallScreen ? pages.concat(topLinks) : pages;

    const [state, dispatch] = useReducer(reducer, initialState);
    const { openSidebar, focusMenuItem, focusedItem } = state;
    const menuItems = [
        {backButtonTitle: "", currentMenu: navigationPanelMenuItems},
        ...state.menuItems
    ];
    const { backButtonTitle, currentMenu } = menuItems[menuItems.length - 1];

    const currentMenuList = useRef<HTMLUListElement>(null);

    const onOpenSidebar = () => {
        dispatch(new ToggleSidebarAction({ focusMenuItem: true }));
    }

    useEffect(() => {
        document.addEventListener('onOpenSidebar', onOpenSidebar);
        return () => {
            document.removeEventListener('onOpenSidebar', onOpenSidebar)
        };
    }, []);

    /*
     * open active part submenu of sidebar
     */
    useEffect(() => {
        const handleFocusFirstMenuItem = () => {
            if (menuItems.length > 1) {
                const firstItem = currentMenuList?.current?.childNodes[1]
                    ?.firstChild as HTMLElement;
                firstItem?.focus();
            } else {
                const firstItem = currentMenuList?.current?.firstChild
                    ?.firstChild as HTMLElement;
                firstItem?.focus();
            }
        };

        currentMenu.forEach((menuItem: SidebarMenuItem) => {
            if (menuItem.active && menuItem.pages?.length && !openSidebar) {
                dispatch(
                    new OpenSubMenuAction({ menuItem, focusMenuItem: false })
                );
            }
        });

        if (focusMenuItem && openSidebar) {
            handleFocusFirstMenuItem();
        }
    }, [currentMenu, openSidebar, focusMenuItem, menuItems.length]);

    /*
     * Handle Submenu arrow icon tabbing events
     */
    const handleKeyDownSubMenuIcon = (
        e: React.KeyboardEvent<HTMLButtonElement>,
        menuItem: SidebarMenuItem
    ) => {
        if (menuItem.pages?.length && e.key === "Enter") {
            dispatch(new OpenSubMenuAction({ menuItem, focusMenuItem: true }));
        }
    };

    /*
     * Handle Submenu arrow icon click event
     */
    const handleClickSubMenuIcon = (menuItem: SidebarMenuItem) => {
        if (menuItem.pages?.length) {
            dispatch(new OpenSubMenuAction({ menuItem, focusMenuItem: true }));
        }
    };

    /*
     * Handle back button click event
     */
    const handleClickBackButton = () => {
        if (backButtonTitle) {
            const backButton = currentMenuList?.current
                ?.firstChild as HTMLElement;
            backButton?.blur();
        }
        dispatch(new CloseSubMenuAction({ focusMenuItem: false }));
    };

    /*
     * Handle back button tabbing events
     */
    const handleKeyDownBackButton = (
        e: KeyboardEvent<HTMLButtonElement>
    ) => {
        if (e.key === "Enter") {
            e.preventDefault();
            dispatch(new CloseSubMenuAction({ focusMenuItem: true }));
        } else if (e.shiftKey && e.key === "Tab") {
            e.preventDefault();
            const closeMenuButton = currentMenuList?.current?.parentElement
                ?.lastChild as HTMLElement;
            closeMenuButton?.focus();
        } else if (e.key === "Escape") {
            handleCloseSidebar(e.key)
        } else if (e.key === "ArrowDown") {
            focusNextItem(e);
        }
    };

    /**
     * Handle menu menuItem tabbing events
     */
    const handleKeyDownMenuItem = (
        e: KeyboardEvent<HTMLElement>,
        _index: number,
        menuItem: SidebarMenuItem
    ) => {
        // arrow down - next item
        if (e.key === "ArrowDown") {
            focusNextItem(e);
        } else if (e.key === "ArrowUp") {
            focusPrevItem(e);
        } else if (e.key === "ArrowRight") {
            handleClickSubMenuIcon(menuItem);
        } else if (e.key === "ArrowLeft") {
            dispatch(new CloseSubMenuAction({ focusMenuItem: true }));
        } else if (e.key === "Escape" || e.key === "Tab") {
            handleCloseSidebar(e.key);
        }
    };

    const focusNextItem = (e: React.KeyboardEvent<HTMLElement>) => {
        const activeElement = e.target as HTMLElement;
        let nextElement = activeElement?.parentElement?.nextElementSibling
            ?.firstChild as HTMLElement;

        // is this the back button
        if (activeElement instanceof HTMLButtonElement) {
            nextElement = activeElement?.nextElementSibling?.firstChild as HTMLElement;
        }

        nextElement && nextElement.focus();
        nextElement &&
        dispatch(new FocusItem({ focusedItem: focusedItem + 1 }));
    };

    const focusPrevItem = (e: React.KeyboardEvent<HTMLElement>) => {
        const activeElement = e.target as HTMLElement;
        let prevElement = activeElement?.parentElement?.previousElementSibling
            ?.firstChild as HTMLElement;

        // is the prev sibling the back button
        if (activeElement?.parentElement?.previousElementSibling instanceof HTMLButtonElement) {
            prevElement = activeElement?.parentElement?.previousElementSibling;
        }

        prevElement && prevElement.focus();
        prevElement &&
        dispatch(new FocusItem({ focusedItem: focusedItem - 1 }));
    };

    const handleCloseSidebar = (key?: string) => {
        dispatch(new ToggleSidebarAction({ focusMenuItem: true }));
        document.dispatchEvent(new CustomEvent('onCloseSidebar', {detail: key ?? "click"}));
    };

    const handleOverlayClick = () => {
        handleCloseSidebar();
    }

    return (
        <>
            <StyledNav
                role="navigation"
                initial={openSidebar}
                animate={openSidebar ? "open" : "closed"}
                variants={variants}
            >
                <Menu
                    ref={currentMenuList}
                    title={backButtonTitle}
                    currentMenu={currentMenu}
                    focusedItem={focusedItem}
                    openSidebar={openSidebar}
                    handleClickBackButton={handleClickBackButton}
                    handleKeyDownSubMenuIcon={handleKeyDownSubMenuIcon}
                    handleClickSubMenuIcon={handleClickSubMenuIcon}
                    handleKeyDownBackButton={handleKeyDownBackButton}
                    handleKeyDownMenuItem={handleKeyDownMenuItem}
                />
            </StyledNav>

            {openSidebar && <Overlay onClick={handleOverlayClick} />}
        </>
    )
}

export default NavigationPanel;
