import { SidebarMenuItem } from "@mediaspace/shared/types/SidebarMenuItem";

const TOGGLE_SIDEBAR = "[Sidebar] Toggle Sidebar";
const OPEN_SUB_MENU = "[Sidebar] Open Sub Menu";
const CLOSE_SUB_MENU = "[Sidebar] Close Sub Menu";
const FOCUS_ITEM = "[Sidebar] Focus Item";

export class ToggleSidebarAction {
    readonly type = TOGGLE_SIDEBAR;
    public payload: { focusMenuItem: boolean };
    constructor(payload: { focusMenuItem: boolean }) {
        this.payload = payload;
    }
}
export class OpenSubMenuAction {
    readonly type = OPEN_SUB_MENU;
    public payload: { menuItem: SidebarMenuItem; focusMenuItem: boolean };
    constructor(payload: {
        menuItem: SidebarMenuItem;
        focusMenuItem: boolean;
    }) {
        this.payload = payload;
    }
}
export class CloseSubMenuAction {
    readonly type = CLOSE_SUB_MENU;
    public payload: { focusMenuItem: boolean };
    constructor(payload: { focusMenuItem: boolean }) {
        this.payload = payload;
    }
}
export class FocusItem {
    readonly type = FOCUS_ITEM;
    public payload: { focusedItem: number };
    constructor(payload: { focusedItem: number }) {
        this.payload = payload;
    }
}

type CurrentMenu = { backButtonTitle: string; currentMenu: SidebarMenuItem[] };

interface State {
    openSidebar: boolean;
    focusMenuItem: boolean;
    menuItems: CurrentMenu[];
    focusedItem: number;
}

type Actions =
    | ToggleSidebarAction
    | OpenSubMenuAction
    | CloseSubMenuAction
    | FocusItem;

export const initialState = {
    openSidebar: false,
    focusMenuItem: false,
    menuItems: [],
    focusedItem: 0,
};

export const reducer = (state: State, action: Actions) => {
    switch (action.type) {
        case TOGGLE_SIDEBAR: {
            const { focusMenuItem } = action.payload;
            return {
                ...state,
                openSidebar: !state.openSidebar,
                focusMenuItem,
                focusedItem: 0
            };
        }
        case OPEN_SUB_MENU: {
            const { menuItem, focusMenuItem } = action.payload;
            if (menuItem.pages?.length) {
                return {
                    ...state,
                    menuItems: [
                        ...state.menuItems,
                        {
                            backButtonTitle: menuItem.label,
                            currentMenu: menuItem.pages,
                        },
                    ],
                    focusMenuItem,
                    focusedItem: 0
                };
            }
            return state;
        }
        case CLOSE_SUB_MENU: {
            const { focusMenuItem } = action.payload;
            return {
                ...state,
                menuItems: state.menuItems.slice(0, -1),
                focusMenuItem,
                focusedItem: 0
            };
        }
        case FOCUS_ITEM: {
            const { focusedItem } = action.payload;
            return {
                ...state,
                focusedItem,
            };
        }
        default: {
            return state;
        }
    }
};
